import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import IndexPage from '../components/IndexPage';
import NotFoundPage from '../components/NotFoundPage';

import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();

const AppRouter = () => (
    <Router history={history}>
        <div>
            <Switch>
                <Route path="/" exact component={IndexPage} />

                <Route component={NotFoundPage} />
            </Switch>
        </div>
    </Router>
);

export default AppRouter;
