import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

class NotFoundPage extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor (props) {
        super(props);
    }

    render () {
        return (
            <div className="container">
                404 - Page Not Found
            </div>
        );
    }
}

export default withRouter(NotFoundPage);
