import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
// import $ from 'jquery';

// import AnimatedSlider from 'react-animated-slider';
import Slider from "react-slick";

import 'react-animated-slider/build/horizontal.css';
import './override.css';

// import { firestore } from '../firebase/firebase.utils';
import { firestore } from '../firebase/firebase.utils';

import Flip from 'react-reveal/Flip';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import Swing from 'react-reveal/Swing';

class IndexPage extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired
    };

    constructor (props) {
        super(props);

        this.contactRef = firestore.collection('contactMessages');

        this.state = {
            heroSlides: [{
                title: 'Welcome',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eget est sodales erat consectetur elementum quis non enim. Praesent nec dignissim dui. Curabitur consequat a diam et efficitur.',
                image: 'https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/slider1.png', // slider1-1500.svg
                rowClasses: 'row align-items-center h-100',
                // classes: 'col-md-8 col-lg-6 ml-auto slide1'
                classes: 'col-lg-6 align-self-center pb-lg-9 ml-auto slide1',
                headerClasses: 'display-3 fw-500 firstslide'
            }, {
                title: 'Increase Advertising Sales By 30%',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eget est sodales erat consectetur elementum quis non enim. Praesent nec dignissim dui. Curabitur consequat a diam et efficitur.',
                image: 'https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/slider2.png',
                rowClasses: 'row align-items-center h-100',
                // classes: 'col-md-8 col-lg-8 ml-auto slide2'
                classes: 'col-lg-6 align-self-center pb-lg-9 ml-auto slide2',
                headerClasses: 'display-3 fw-500'
            }, {
                title: 'Award Winning Publications',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eget est sodales erat consectetur elementum quis non enim. Praesent nec dignissim dui. Curabitur consequat a diam et efficitur.',
                image: 'https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/slider3.png',
                rowClasses: 'row align-items-center h-100',
                // classes: 'col-md-8 col-lg-6 slide3'
                classes: 'col-lg-6 align-self-center pb-lg-13',
                headerClasses: 'display-3 fw-500'
            }, {
                title: 'Interactive Mobile Technologies',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eget est sodales erat consectetur elementum quis non enim. Praesent nec dignissim dui. Curabitur consequat a diam et efficitur.',
                image: 'https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/slider4.png',
                rowClasses: 'row align-items-center',
                // classes: 'col-md-12 col-lg-12 slide4'
                classes: 'col-lg-6 align-self-center pb-lg-9',
                headerClasses: 'display-3 fw-500'
            }]
        };
    }

    submitContactForm = e => {
        // Prevent the page from performing a hard-refresh
        e.preventDefault();
        
        // Grab all the parts of the contact form
        const { contactName, contactEmail, contactMessage } = this.state;

        this.contactRef
            .add({
                name: contactName,
                email: contactEmail,
                message: contactMessage,
                source: 'prospects',
                responded: false
            })
            .then(() => {
                // Clear out the contact form elements of the state
                this.setState({
                    contactName: '',
                    contactEmail: '',
                    contactMessage: '',
                    contactFormSubmitted: true
                });
            })
            .catch((error) => console.error('Error submitting form', ' => ', error));
    };

    render () {
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-dark" data-navbar="fixed" style={{ background: '#fff', top: '0px' }}>
                    <div className="container">
                        <div className="navbar-left">
                            <button className="navbar-toggler" type="button">&#9776;</button>
                            <a className="navbar-brand" href="#">
                                <img
                                    className="logo-dark"
                                    src="https://lunarcow.nyc3.digitaloceanspaces.com/img/logo-dark.png"
                                    alt="logo"
                                    style={{ height: '55px' }} />
                            </a>
                        </div>

                        <section className="navbar-mobile">
                            <nav className="nav nav-navbar ml-auto">
                                <a className="nav-link" href="#resources">Resources</a>
                                <a className="nav-link" href="#about">About</a>
                                <a className="nav-link" href="#testimonials">Testimonials</a>
                                <a className="nav-link" href="#team">Team</a>
                                <a className="nav-link" href="#case-study">Case Study</a>
                                <a className="nav-link" href="#process">Process</a>
                                <a className="nav-link" href="#contact">Contact</a>
                            </nav>
                        </section>
                    </div>
                </nav>

                <header className="header h-fullscreen text-white text-lg-left pb-0" style={{ backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/slider1.png)' }}>
                    <div className="container">
                        <div className="row h-100 hero-slider-text">
                            {/*
                            <div className="col-lg-6 align-self-center pb-lg-9 ml-auto slide1">
                                <h1 className="display-3 fw-500 firstslide tk-futura-pt-bold">WELCOME</h1>
                            </div>
                            */}
                            <div className="col-12 align-self-end text-center mt-6">
                                <a  className="scroll-down-1 scroll-down-white" href="#resources"><span></span></a>
                            </div>
                        </div>
                    </div>
                </header>

                <main className="main-content">
                    {/* Section: Resources */}
                    <section id="resources" className="section bg-secondary">
                        <div className="container">
                            <header className="section-header">
                                <h2 className="font-weight-700 tk-futura-pt-bold">RESOURCES</h2>
                                <hr />
                                <p className="lead">
                                    Explore the resources below to help you create an award-winning publication. Increase advertising sales by 30%, reduce manufacturing costs and bring more value to your members, partners as well as your community.
                                </p>
                            </header>

                            <div className="row gap-y">
                                <div className="col-3 col-md-3 col-xl-3">
                                    <Flip left cascade>
                                        <a className="text-center py-6" href="https://lunarcow.com/5-deadliest-trends" target="_blank">
                                            <img src="https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/deadly-trends.png" />
                                        </a>
                                    </Flip>
                                    <h5 className="text-center mt-3 feature-box"><strong>Deadly Trends in Publishing</strong></h5>
                                </div>

                                <div className="col-3 col-md-3 col-xl-3">
                                    <Flip left cascade>
                                        <a className="text-center py-6" href="https://mydigitalpublication.com/publication/?m=20432&l=1#{%22issue_id%22:484822,%22page%22:0}" target="_blank">
                                            <img src="https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/corp-overview.png" />
                                        </a>
                                    </Flip>
                                    <h5 className="text-center mt-3 feature-box"><strong>Corporate Overview</strong></h5>
                                </div>

                                <div className="col-3 col-md-3 col-xl-3">
                                    <Flip left cascade>
                                        <a className="text-center py-6" href="https://presentation.lunarcow.com/library" target="_blank">
                                            <img src="https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/image-resource-library.png" />
                                        </a>
                                    </Flip>
                                    <h5 className="text-center mt-3 feature-box"><strong>Image Resource Library</strong></h5>
                                </div>

                                <div className="col-3 col-md-3 col-xl-3">
                                    <Flip left cascade>
                                        <a className="text-center py-6" href="https://presentation.lunarcow.com/board" target="_blank">
                                            <img src="https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/board-presentation.png" />
                                        </a>
                                    </Flip>
                                    <h5 className="text-center mt-3 feature-box"><strong>Board Presentation</strong></h5>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Section: About */}
                    <section id="about" className="section">
                        <div className="container">
                            <header className="section-header">
                                <h2 className="font-weight-700 tk-futura-pt-bold">ABOUT</h2>
                                <hr />
                                <p className="lead">
                                    Key differentiation, competitive advantage and specialization are the secrets to successful publishing. Examine the links below to learn how to create a publication that separates your organization from all others.
                                </p>
                            </header>

                            <div className="row gap-y">
                                <div className="col-3 col-md-3 col-xl-3">
                                    <Flip right cascade>
                                        <a
                                            className="hover-shadow-2 text-center py-6"
                                            href="https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/about-lc-full.png"
                                            target="_blank">
                                            <img src="https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/about-lc.png" />
                                        </a>
                                    </Flip>
                                    <h5 className="text-center mt-3 feature-box"><strong>About Lunar Cow</strong></h5>
                                </div>

                                <div className="col-3 col-md-3 col-xl-3">
                                    <Flip right cascade>
                                        <a
                                            className="hover-shadow-2 text-center py-6"
                                            href="https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/ad-sales-full.png"
                                            target="_blank">
                                            <img src="https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/ad-sales.png" />
                                        </a>
                                    </Flip>
                                    <h5 className="text-center mt-3 feature-box"><strong>Ad Sales</strong></h5>
                                </div>

                                <div className="col-3 col-md-3 col-xl-3">
                                    <Flip right cascade>
                                        <a
                                            className="hover-shadow-2 text-center py-6"
                                            href="https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/technology-full.png"
                                            target="_blank">
                                            <img src="https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/mobile-tech.png" />
                                        </a>
                                    </Flip>
                                    <h5 className="text-center mt-3 feature-box"><strong>Mobile Technology</strong></h5>
                                </div>

                                <div className="col-3 col-md-3 col-xl-3">
                                    <Flip right cascade>
                                        <a
                                            className="hover-shadow-2 text-center py-6"
                                            href="https://www.youtube.com/watch?v=a34tIQWgUl0"
                                            target="_blank">
                                            <img src="https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/increase-revenue.png" />
                                        </a>
                                    </Flip>
                                    <h5 className="text-center mt-3 feature-box"><strong>Increase Revenue</strong></h5>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Section: About */}
                    <section id="about" className="section bg-gray bg-fixed text-white" style={{ backgroundColor: '#8ea6e6', backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/bg3.png)' }} data-overlay="6">
                        <div className="container">
                            <header className="section-header">
                                <h2 className="font-weight-700 tk-futura-pt-bold">MOBILE INTERACTIVE TECHNOLOGIES</h2>
                                <hr />
                                <p className="lead">
                                    Advanced mobile technologies designed to generate new members, increase profitable revenues and integrate our latest technology platforms seamlessly into your existing systems.
                                </p>
                            </header>

                            <div className="row gap-y">
                                <div className="col-6 col-md-6 col-xl-6">
                                    <Slide left>
                                        <a className="card card-body border hover-shadow-6 text-center py-6" href="https://presentation.lunarcow.com/goguide" target="_blank">
                                            <p style={{ color: '#78a300' }}>
                                                <img src="https://lunarcow.nyc3.digitaloceanspaces.com/products/goguide-220x65.png" style={{ height: '65px' }} />
                                            </p>
                                            <h6 className="mb-0">
                                                <strong>GoGuide</strong>
                                            </h6>
                                        </a>
                                    </Slide>
                                </div>

                                <div className="col-6 col-md-6 col-xl-6">
                                    <Slide right>
                                        <a className="card card-body border hover-shadow-6 text-center py-6" href="https://presentation.lunarcow.com/imap" target="_blank">
                                            <p style={{ color: '#eb4a62' }}>
                                                <img src="https://lunarcow.nyc3.digitaloceanspaces.com/products/imap-widget.svg" style={{ height: '65px' }} />
                                            </p>
                                            <h6 className="mb-0">
                                                <strong>iMap</strong>
                                            </h6>
                                        </a>
                                    </Slide>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Section: Testimonials */}
                    <section id="testimonials" className="section bg-secondary">
                        {/*<div className="overlay opacity-95" style={{ backgroundImage: 'linear-gradient(135deg, #000 0%, #000 20%, transparent 100%)' }} />*/}
                        <div className="container">
                            <header className="section-header">
                                <h2 className="font-weight-700 tk-futura-pt-bold">TESTIMONIALS</h2>
                                {/*
                                <hr />
                                <p className="lead">
                                    Meet the team at Lunar Cow Publishing. Let our team help grow your business by acquiring more new customers.
                                </p>
                                */}
                            </header>

                            <Slider slidesToShow={1} slidesToScroll={1} dots={false} infinite={true} speed={500} arrows={false} autoplay={true}>
                                <div className="px-6">
                                    <blockquote className="blockquote">
                                        <p className="lead-4">I'm so glad Lunar Cow found us three years ago! We've worked with them on 3 membership guides and we couldn't be happier with the finished products. The entire team is top notch from the sales to the design. They make the process so smooth and painless.</p>
                                        <br />
                                        <div><img className="avatar avatar-xl" src="https://lunarcow.nyc3.digitaloceanspaces.com/ratecard/hope-allen.jpg" alt="..." /></div>
                                        <footer>Hope Kennedy Allen, FCCP</footer>
                                    </blockquote>
                                </div>

                                <div className="px-6">
                                    <blockquote className="blockquote">
                                        <p className="lead-4">After five years of working with Lunar Cow to produce our annual visitors guide, they continue to create visually appealing cover designs and page styles that are fresh and reflect of our brand and destination.</p>
                                        <br />
                                        <div><img className="avatar avatar-xl" src="https://lunarcow.nyc3.digitaloceanspaces.com/ratecard/wendy-haase.jpg" alt="..." /></div>
                                        <footer>Wendy Haase</footer>
                                    </blockquote>
                                </div>

                                <div className="px-6">
                                    <blockquote className="blockquote">
                                        <p className="lead-4">Lunar Cow has perfected the process of producing destination Visitors Guides. Each year they somehow enhance the process making it easier on me. I am amazed at the creativity put into each and every guide they produce. We have been working with Lunar Cow for 13+ years and have received nothing short of outstanding work and customer service.</p>
                                        <br />
                                        <div><img className="avatar avatar-xl" src="https://lunarcow.nyc3.digitaloceanspaces.com/ratecard/kim-kislowski.jpg" alt="..." /></div>
                                        <footer>Kim Kislowski</footer>
                                    </blockquote>
                                </div>

                                <div className="px-6">
                                    <blockquote className="blockquote">
                                        <p className="lead-4">Lunar Cow Publishing recently created our latest visitor guide. It is extremely popular with our members and this is the best guide we have ever distributed. The team is fantastic to work with. They took the time to learn the territory and developed excellent relationships with our members during the sales process. I highly recommend Lunar Cow to other organizations that desire quality marketing and advertising publications.</p>
                                        <br />
                                        <div><img className="avatar avatar-xl" src="https://lunarcow.nyc3.digitaloceanspaces.com/ratecard/bill-wells.jpg" alt="..." /></div>
                                        <footer>Bill Wells</footer>
                                    </blockquote>
                                </div>
                            </Slider>
                        </div>
                    </section>

                    {/* Section: Call To Action -- Schedule Demo, Get Quote*/}
                    <section className="section text-center py-10 bg-fixed text-white" style={{ backgroundColor: '#8ea6e6', backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/bg1.png)' }} data-overlay="6">
                        <div className="container">
                            <Fade up>
                                <header className="section-header">
                                    <h2 className="text-white font-weight-700 tk-futura-pt-bold">LET'S CONNECT</h2>
                                    <hr />
                                    <p className="lead">
                                        Learn how to create an award winning publication. Increase profitability, decrease costs and bring more value to your organization. Get a quote, compare, and save!
                                    </p>
                                </header>

                                <div className="col-md-6 text-center mx-auto">
                                    <a
                                        className="btn btn-xl btn-round btn-light"
                                        href="http://www.lunarcow.com/schedule-demo"
                                        target="_blank"
                                        style={{ marginRight: '55px' }}>
                                        Schedule a Demo
                                    </a>

                                    <a
                                        className="btn btn-xl btn-round btn-light"
                                        href="http://www.lunarcow.com/get-quote"
                                        target="_blank">
                                        Get a Quote
                                    </a>
                                </div>
                            </Fade>
                        </div>
                    </section>

                    {/* Section: Team */}
                    <section id="team" className="section bg-gray">
                        <div className="container">
                            <Fade up>
                                <header className="section-header">
                                    <h2 className="font-weight-700 tk-futura-pt-bold">TEAM</h2>
                                    <hr />
                                    <p className="lead">
                                        Meet the team at Lunar Cow Publishing. Let our team help grow your publication by increasing revenues, decreasing costs and by integrating the latest mobile technologies.
                                    </p>
                                </header>

                                {/* Ben, Dave, Amber, Mike, Adam */}

                                <div className="row gap-y">
                                    <div className="col-md-12">
                                        <Slider slidesToShow={3} slidesToScroll={1} dots={false} infinite={true} speed={500} arrows={true} autoplay={true}>
                                            <div className="team-2" ref="edasper">
                                                <img src="https://lunarcow.nyc3.digitaloceanspaces.com/employees/images/ben-harris.jpg" alt="..." style={{ height: '200px' }} />
                                                <h5>Ben Harris</h5>
                                                <small>CEO</small>
                                                <p>
                                                    ben@lunarcow.com<br />
                                                    (330) 256-9000 x111
                                                </p>
                                            </div>

                                            <div className="team-2" ref="edasper">
                                                <img src="https://lunarcow.nyc3.digitaloceanspaces.com/employees/images/dave-potokar.jpg" alt="..." style={{ height: '200px' }} />
                                                <h5>Dave Potokar</h5>
                                                <small>Senior Designer</small>
                                                <p>
                                                    dave@lunarcow.com<br />
                                                    (330) 256-9000 x126
                                                </p>
                                            </div>

                                            <div className="team-2" ref="edasper">
                                                <img src="https://lunarcow.nyc3.digitaloceanspaces.com/employees/images/amber-copley.jpg" alt="..." style={{ height: '200px' }} />
                                                <h5>Amber Copley</h5>
                                                <small>Project Manager</small>
                                                <p>
                                                    amber@lunarcow.com<br />
                                                    (330) 256-9000 x117
                                                </p>
                                            </div>

                                            <div className="team-2" ref="edasper">
                                                <img src="https://lunarcow.nyc3.digitaloceanspaces.com/employees/images/mike-hatter.jpg" alt="..." style={{ height: '200px' }} />
                                                <h5>Mike Hatter</h5>
                                                <small>Senior Full Stack Engineer</small>
                                                <p>
                                                    mike@lunarcow.com<br />
                                                    (330) 256-9000 x118
                                                </p>
                                            </div>

                                            <div className="team-2" ref="edasper">
                                                <img src="https://lunarcow.nyc3.digitaloceanspaces.com/employees/images/ed-asper.jpg" alt="..." style={{ height: '200px' }} />
                                                <h5>Edward Asper</h5>
                                                <small>Ad Sales Manager</small>
                                                <p>
                                                    edasper@lunarcow.com<br />
                                                    (330) 256-9000 x131
                                                </p>
                                            </div>

                                            <div className="team-2" ref="edasper">
                                                <img src="https://lunarcow.nyc3.digitaloceanspaces.com/employees/images/adam-moser.jpg" alt="..." style={{ height: '200px' }} />
                                                <h5>Adam Moser</h5>
                                                <small>Account Manager</small>
                                                <p>
                                                    amoser@lunarcow.com<br />
                                                    (330) 256-9000 x113
                                                </p>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </section>

                    {/* Section: Case Study */}
                    <section id="case-study" className="section bg-secondary bg-fixed" style={{ backgroundColor: '#8ea6e6', color: '#fff', backgroundImage: 'url(https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/bg2.png)' }} data-overlay="6">
                        <div className="container">
                            <div className="row gap-y align-items-center">
                                <Slide left>
                                    <div className="col-md-3 text-center">
                                        <img
                                            src="https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/case-study-old.jpg"
                                            alt="..."
                                            style={{ height: '300px' }} />
                                        <h4 className="text-center mt-3 font-weight-600 text-white">BEFORE</h4>
                                    </div>

                                    <div className="col-md-3 text-center">
                                        <a href="" onClick={e => window.open('https://mydigitalpublication.com/publication/?m=54817&l=1#{%22issue_id%22:595327,%22page%22:0}')}>
                                            <img
                                                src="https://lunarcow.nyc3.digitaloceanspaces.com/presentations/prospects/case-study-new.jpg"
                                                alt="..."
                                                style={{ height: '300px' }} />
                                        </a>
                                        <h4 className="text-center mt-3 font-weight-600 text-white">AFTER</h4>
                                    </div>
                                </Slide>

                                <Slide right>
                                    <div className="col-md-6 text-center text-md-left">
                                        <h2 className="text-white tk-futura-pt-bold">CASE STUDY</h2>
                                        <ul className="list-unstyled lead mb-6">
                                            <li><i className="ti-check text-success mr-2"></i> Enhanced Overall Design</li>
                                            <li><i className="ti-check text-success mr-2"></i> Increased Advertising Sales By 60%</li>
                                            <li><i className="ti-check text-success mr-2"></i> Decreased Costs By 25%</li>
                                            <li><i className="ti-check text-success mr-2"></i> Improved Production Units by 30%</li>
                                        </ul>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </section>

                    {/* Section: Publishing Process (Infographic Slider) */}
                    <section id="process" className="section bg-gray">
                        <div className="container">
                            <header className="section-header">
                                <h2 className="font-weight-700 tk-futura-pt-bold">PUBLISHING PROCESS</h2>
                                <hr />
                                <p className="lead">
                                    Learn how our integrated hybrid approach to publishing yields positive results, generates profitable revenue, enhances quality and reduces costs.
                                </p>
                            </header>

                            <div className="row gap-y">
                                <div className="col-md-12">
                                    <Slider slidesToShow={3} slidesToScroll={1} dots={false} infinite={true} speed={500} arrows={false} autoplay={true}>
                                        <div className="p-2">
                                            <a href="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-barnstorming.png" target="_blank">
                                                <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/process-barnstorming-thumb.png" />
                                            </a>
                                        </div>
                                        <div className="p-2">
                                            <a href="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-advertising-model.png" target="_blank">
                                                <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/process-advertising-model-thumb.png" />
                                            </a>
                                        </div>
                                        <div className="p-2">
                                            <a href="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-sales-promo.png" target="_blank">
                                                <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/process-sales-promo-thumb.png" />
                                            </a>
                                        </div>
                                        <div className="p-2">
                                            <a href="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-project-management.png" target="_blank">
                                                <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/process-project-management-thumb.png" />
                                            </a>
                                        </div>
                                        <div className="p-2">
                                            <a href="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-printing.png" target="_blank">
                                                <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/process-printing-thumb.png" />
                                            </a>
                                        </div>
                                        <div className="p-2">
                                            <a href="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/infographics/process-shipping.png" target="_blank">
                                                <img src="https://lunarcow.nyc3.cdn.digitaloceanspaces.com/presentations/board/img/process-shipping-thumb.png" />
                                            </a>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Section Contact Us */}
                    <section id="contact" className="section bg-secondary">
                        <div className="container">
                            <div className="row gap-y">
                                <Slide left>
                                    <div className="col-lg-5 ml-auto text-center text-lg-left">
                                        <hr className="d-lg-none" />

                                        <h3 className="tk-futura-pt-bold">FIND US</h3>
                                        <br />
                                        <p>344 W Turkeyfoot Lake Rd, Suite B<br />Akron, OH 44319</p>
                                        <p>+1 (330) 253-9000<br />+1 (800) 594-9620</p>
                                        <p>info@lunarcow.com</p>
                                    </div>
                                </Slide>

                                <Swing>
                                    <div className="col-lg-6">
                                        <h3 className="tk-futura-pt-bold">CONTACT US</h3>
                                        <br />

                                        {this.state.contactFormSubmitted &&
                                            <div className="alert alert-success">We received your message and will contact you soon.</div>
                                        }

                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <input
                                                    className="form-control form-control-lg"
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name"
                                                    value={this.state.contactName}
                                                    onChange={e => this.setState({ contactName: e.target.value })}
                                                />
                                            </div>

                                            <div className="form-group col-md-6">
                                                <input
                                                    className="form-control form-control-lg"
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    value={this.state.contactEmail}
                                                    onChange={e => this.setState({ contactEmail: e.target.value })}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <textarea
                                                className="form-control form-control-lg"
                                                rows="4"
                                                placeholder="Message"
                                                name="message"
                                                value={this.state.contactMessage}
                                                onChange={e => this.setState({ contactMessage: e.target.value })}
                                            />
                                        </div>

                                        <button
                                            className="btn btn-lg"
                                            type="submit"
                                            style={{ background: '#70d0f6', color: '#fff' }}
                                            onClick={this.submitContactForm}>
                                            Send message
                                        </button>
                                    </div>
                                </Swing>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        );
    }
}

export default withRouter(IndexPage);
