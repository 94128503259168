import firebase from 'firebase/app';
import "firebase/performance";
import "firebase/analytics";
import 'firebase/firestore';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyBvvVwTjvXkvkRIK_Is5kBAaBKnMC_hhAg",
    authDomain: "lunar-cow-publishing.firebaseapp.com",
    databaseURL: "https://lunar-cow-publishing.firebaseio.com",
    projectId: "lunar-cow-publishing",
    storageBucket: "lunar-cow-publishing.appspot.com",
    messagingSenderId: "78561151090",
    appId: "1:78561151090:web:38890e66c3087b32129aeb",
    measurementId: "G-NKWNWKPGFL"
};

// Initialize the firebase app with our config
firebase.initializeApp(config);

// Set up firebase performance monitoring
// const perf =
firebase.performance();

// const analytics = 
firebase.analytics();

// Export auth-related imports
export const auth = firebase.auth();

// Export firestore
export const firestore = firebase.firestore();

// Export firebase/app
export default firebase;
